import { cn } from "@/lib/utils";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Trans } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { Combobox } from "./combobox";
import { buttonVariants } from "./ui/button";

type TProps = {
  currentPage: number;
  totalPage: number;
  className?: string;
  path: string;
  limit?: number;
};

export default function Pagination(props: TProps) {
  const { currentPage, totalPage, className, path, limit = 10 } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const limitOptions = [
    { value: limit.toString(), label: limit.toString() },
    { value: "15", label: "15" },
    { value: "25", label: "25" },
    { value: "50", label: "50" },
  ];

  const getQueriedURL = (key: string | null, value: string | null) => {
    const params = new URLSearchParams(searchParams);
    if (!key) return params.toString();

    if (!value) {
      params.delete(key);
      return params.toString();
    }

    params.set(key, value);

    return params.toString();
  };

  return (
    <div className={cn("border border-white/5 bg-white/5 md:p-2.5 flex flex-col", className)}>
      <div className="bg-gray-scale-5 flex items-center justify-center text-gray-scale-4 text-sm p-2 md:hidden">
        <Trans i18nKey="common.pagination.pageOf" values={{ page: currentPage, total: totalPage }} />
      </div>
      <div className="p-2.5 flex items-center justify-between md:p-0">
        {/* Pages */}
        <div className="flex items-center dir-rtl">
          {currentPage !== totalPage ? (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage + 1}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }))}
            >
              <ChevronRight />
            </NavLink>
          ) : null}

          {currentPage < totalPage - 3 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage + 4}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage + 4}
            </NavLink>
          )}

          {currentPage < totalPage - 2 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage + 3}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage + 3}
            </NavLink>
          )}

          {currentPage < totalPage - 1 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage + 2}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage + 2}
            </NavLink>
          )}

          {currentPage < totalPage && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage + 1}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage + 1}
            </NavLink>
          )}

          <div
            data-disabled="true"
            className={cn(
              buttonVariants({ variant: "secondary", size: "icon" }),
              "border border-primary text-primary data-[disabled=true]:pointer-events-none",
            )}
          >
            {currentPage}
          </div>

          {currentPage > 1 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage - 1}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage - 1}
            </NavLink>
          )}

          {currentPage > 2 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage - 2}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage - 2}
            </NavLink>
          )}

          {currentPage > 3 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage - 3}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage - 3}
            </NavLink>
          )}

          {currentPage > 4 && (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage - 4}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }), "hidden md:flex")}
            >
              {currentPage - 4}
            </NavLink>
          )}

          {currentPage !== 1 ? (
            <NavLink
              to={`${path}?${getQueriedURL("page", `${currentPage - 1}`)}`}
              className={cn(buttonVariants({ variant: "secondary", size: "icon" }))}
            >
              <ChevronLeft />
            </NavLink>
          ) : null}
        </div>
        {/* Limit */}
        <div>
          <Combobox
            selected={limitOptions.find((option) => option.value === searchParams.get("limit")) ?? limitOptions[0]}
            onSelect={(selected) =>
              setSearchParams((prev) => {
                prev.set("limit", selected);
                return prev;
              })
            }
            options={limitOptions}
          />
        </div>
      </div>
    </div>
  );
}
